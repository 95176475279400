import { REDUX_CUSTOMER_DEFAULTS } from "../config"

const initialState = {
  customerListData: [],
  // Add other variables here
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_CUSTOMER_DEFAULTS.GET_CUSTOMERS():
      return {
        ...state,
        customerListData: action.payload,
      }

    default:
      return state
  }
}

export default customerReducer
