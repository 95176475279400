import React, { useEffect, useRef, useState } from "react"
import { Header } from "../UI"
import DataTable from "react-data-table-component"
import {
  createCoupon,
  createGiftCard,
  deleteCoupon,
  deleteGiftCard,
  getAllCoupon,
  getAllGiftCard,
} from "../../axios/api/apiModule"
import moment from "moment"
import { useDispatch } from "react-redux"
import { openToastify } from "../store/actions/toastifyAction"
import { capitalize, upperCase } from "lodash"
import { REDUX_TOASTIFY_DEFAULTS } from "../store/config"
import { toPng } from "html-to-image"
import logo from "../asserts/Logo_svg.svg"

const Discount = () => {
  const dispatch = useDispatch()
  const [list, setList] = useState(0)
  const [discount, setDiscount] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [discounData, setDiscounData] = useState()
  const [couponData, setCouponData] = useState()
  const [giftCardData, setGiftCardData] = useState()
  const [discountDataType, setDiscountDataType] = useState("coupon")
  const [isViewDiscount, setIsViewDiscount] = useState()

  const changeData = (e) => {
    setIsLoading(true)
    if (e === "coupon") {
      setDiscountDataType("coupon")
      setDiscounData(couponData)
    } else if (e === "giftCard") {
      setDiscountDataType("giftCard")
      setDiscounData(giftCardData)
    } else {
      setDiscountDataType("all")
      const sortedData = [...couponData, ...giftCardData].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      setDiscounData(sortedData)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (couponData && giftCardData) {
      changeData("all")
    }
  }, [couponData, giftCardData])

  const handleCreateCouponApi = async () => {
    setIsLoading(true)
    try {
      const req = {
        couponName: upperCase(discount.couponName).replace(/\s/g, ""),
        locationType: discount.locationType || "both",
        minOrderValue: parseInt(discount.minOrderValue),
        discountType: discount.discountType || "fixed",
        discountAmt: discount.discountAmt
          ? parseInt(discount.discountAmt)
          : parseInt(discount.maxDiscount),
        maxDiscount: parseInt(discount.maxDiscount),
        maxUsed: parseInt(discount.maxUsed),
        expiryDate: moment(discount.expiryDate).format("yyyy-MM-DD"),
        desc: discount.desc,
        isStartDate: discount.startDate ? true : false,
        startDate: moment(discount.startDate).format("yyyy-MM-DD"),
      }
      const response = await createCoupon(req)
      console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Coupon Created Successful",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        setList(0)
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          error?.response?.data?.msg ?? "Internal Server Error",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
    setIsLoading(true)
    getAllGiftCardApi()
  }
  const handleCreateGiftCardApi = async () => {
    setIsLoading(true)
    try {
      const req = {
        minOrderValue: parseInt(discount.minOrderValue),
        discountAmount: parseInt(discount.discountAmount),
        assignedTo: parseInt(discount.assignedTo),
        expiryDate: moment(discount.expiryDate).format("yyyy-MM-DD"),
      }
      const response = await createGiftCard(req)
      console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Gift Card Created Successful",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        setList(0)
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          error?.response?.data?.msg ?? "Internal Server Error",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
    setIsLoading(true)
    getAllGiftCardApi()
  }

  const handleSubmitCoupon = (e) => {
    e.preventDefault()
    console.log(e)
    handleCreateCouponApi()
  }
  const handleSubmitGiftCard = (e) => {
    e.preventDefault()
    console.log(e)
    handleCreateGiftCardApi()
  }

  const handleDiscountInfoInput = (event) => {
    const { name, value } = event.target
    console.log(value)

    setDiscount((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }))
  }

  const hitDeleteCouponApi = async (id) => {
    try {
      const response = await deleteCoupon({ id })
      console.log(response)
      if (response.success) {
        dispatch(
          openToastify(
            "Coupon Deleted Successful",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          "Something Went Wrong",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  const hitDeleteGiftCardApi = async (id) => {
    try {
      const response = await deleteGiftCard({ id })
      if (response.success) {
        dispatch(
          openToastify(
            "Gift Card Deleted Successful",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          "Something Went Wrong",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  const remove = (id, type) => {
    console.log(id, "delete")
    if (type === "coupon") {
      hitDeleteCouponApi(id)
    } else {
      hitDeleteGiftCardApi(id)
    }
    getAllGiftCardApi()
  }

  const getAllGiftCardApi = async () => {
    setIsLoading(true)
    try {
      const giftcardsResponse = await getAllGiftCard()
      const couponsResponse = await getAllCoupon()

      const giftcards = giftcardsResponse.data.giftCards.map((item) => ({
        id: item._id,
        name: item.giftCardName,
        used: item.giftcardUsed.isRedeemed ? "Yes" : "No",
        locationType: item.locationType,
        minOrderValue: item.minOrderValue,
        discountAmount: item.discountAmount,
        expiryDate: item.expiryDate,
        createdAt: item.createdAt,
        assignedTo: item.assignedTo,
        isEventAddedToCalender: item.isEventAddedToCalender ? "Yes" : "No",
        type: "giftcard",
      }))

      const coupons = couponsResponse.data.coupons.map((item) => ({
        id: item._id,
        name: item.couponName,
        locationType: item.locationType,
        minOrderValue: item.minOrderValue,
        maxDiscount: item.maxDiscount,
        discountAmount: item.discountAmt,
        used: item.numberUsed + " times",
        maxUsed: item.maxUsed,
        expiryDate: item.expiryDate,
        createdAt: item.createdAt,
        isEventAddedToCalender: item.isEventAddedToCalender ? "Yes" : "No",
        type: "coupon",
        desc: item.desc,
      }))

      setGiftCardData(giftcards)
      setCouponData(coupons)
      changeData("coupon")
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(error.msg, REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR())
      )
    }
    setIsLoading(false)
  }

  const couponRef = useRef(null)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const download = () => {
    if (couponRef.current === null) {
      return
    }

    toPng(couponRef.current)
      .then((dataUrl) => {
        const link = document.createElement("a")
        link.href = dataUrl
        link.download = "coupon.png"
        link.click()
      })
      .catch((err) => {
        console.error("Failed to download image", err)
      })
  }

  useEffect(() => {
    // download()
    getAllGiftCardApi()
  }, [])

  const handleView = (data) => {
    setIsViewDiscount(data)
    console.log("ViewDiscount", data)
  }

  const mainColumns = [
    {
      name: "To",
      selector: (row) => (row.assignedTo ? row.assignedTo : "Na"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.desc ? row.desc : "Na"),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => capitalize(row.type),
      sortable: true,
    },
    {
      name: "Number",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Redeemed",
      selector: (row) => row.used,
      sortable: true,
    },
    {
      name: "Location Type",
      selector: (row) => capitalize(row.locationType),
      sortable: true,
    },
    // {
    //   name: "Minimum Order Value",
    //   selector: (row) => row.minOrderValue,
    //   sortable: true,
    // },
    // {
    //   name: "Max Discount",
    //   selector: (row) => (row.maxDiscount ? row.maxDiscount : "Na"),
    //   sortable: true,
    // },
    {
      name: "Discount Amount",
      selector: (row) => row.discountAmount,
      sortable: true,
    },
    {
      name: "Expiry Date",
      selector: (row) => moment(row.expiryDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    // {
    //   name: "Created At",
    //   selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    //   sortable: true,
    // },
    {
      name: "Action",
      // selector: (row) => row.paid,
      cell: (row) => (
        <div className="flex bg-white space-x-1 border-2 shadow-sm shadow-gray-700 rounded-lg p-2">
          <svg
            onClick={() => handleView(row)}
            className="size-6 cursor-pointer text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>
          <svg
            className="size-6 cursor-pointer text-red-600"
            onClick={() => remove(row.id, row.type)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      ),
      sortable: true,
      reorder: true,
    },
  ]
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Discount" />

      <div className="space-x-3 -mt-6 mb-4">
        <span
          className={` cursor-pointer ${
            list === 0
              ? "underline underline-offset-2 text-yellow-medium_dark"
              : ""
          }`}
          onClick={() => {
            setList(0)
          }}
        >
          List
        </span>
        <span
          className={` cursor-pointer ${
            list === 1
              ? "underline underline-offset-2 text-yellow-medium_dark"
              : ""
          }`}
          onClick={() => {
            setList(1)
          }}
        >
          Coupon
        </span>
        <span
          className={` cursor-pointer ${
            list === 2
              ? "underline underline-offset-2 text-yellow-medium_dark"
              : ""
          }`}
          onClick={() => {
            setList(2)
          }}
        >
          Gift Card
        </span>
      </div>

      {list === 0 && (
        <div>
          <div className="text-white space-x-2">
            <button
              className={` ${
                discountDataType === "coupon"
                  ? "bg-yellow-light_medium"
                  : "text-black border border-black"
              } h-8 px-5 rounded-lg `}
              onClick={() => {
                changeData("coupon")
              }}
            >
              Coupons
            </button>
            <button
              className={` ${
                discountDataType === "giftCard"
                  ? "bg-yellow-light_medium"
                  : "text-black border border-black"
              } h-8 px-5 rounded-lg `}
              onClick={() => {
                changeData("giftCard")
              }}
            >
              Gift Cards
            </button>
            <button
              className={` ${
                discountDataType === "all"
                  ? "bg-yellow-light_medium"
                  : "text-black border border-black"
              } h-8 px-5 rounded-lg `}
              onClick={() => {
                changeData("all")
              }}
            >
              All Discounts
            </button>
          </div>
          <DataTable
            // title="Coupons List"
            columns={mainColumns}
            data={discounData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="480px"
          />
        </div>
      )}

      {list === 1 && (
        <form onSubmit={handleSubmitCoupon}>
          <h1 className="text-xl">Create Coupons</h1>
          <div className="flex flex- col justify- center items-center gap-x-28 gap-y-10 flex-wrap pt-5">
            <div className="flex flex-col">
              <span>Enter Coupon Name:</span>
              <input
                type="text"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="desc"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Enter Coupon Number:</span>
              <input
                type="text"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="couponName"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Minimum Order Value (Mini. Price):</span>
              <input
                type="number"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="minOrderValue"
                required
                min={
                  discount.discountType === "fixed" ? discount.maxDiscount : 0
                }
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Maximum Discount (Max Discount Price):</span>
              <input
                type="number"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="maxDiscount"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Maximum Used (Using Limit):</span>
              <input
                type="number"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="maxUsed"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Expiry Date of Coupon:</span>
              <input
                type="date"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="expiryDate"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Start Date of Coupon:</span>
              <input
                type="date"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="startDate"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Select Location (Shop or Mobile):</span>
              <select
                name="locationType"
                id="location"
                className="uppercase bg-main-bg p-2"
                defaultValue="both" // Set the default value here
                onChange={(e) => handleDiscountInfoInput(e)}
              >
                <option value="both">both</option>
                <option value="shop">shop</option>
                <option value="mobile">mobile</option>
              </select>
            </div>
            <div className="flex flex-col">
              <span>Discount Type ($ or %):</span>
              <select
                name="discountType"
                id="Discount"
                className="uppercase bg-main-bg p-2"
                defaultValue="fixed" // Set the default value here
                onChange={(e) => handleDiscountInfoInput(e)}
              >
                <option value="fixed">fixed</option>
                <option value="percentage">percentage</option>
              </select>
            </div>
            {discount.discountType === "percentage" && (
              <div className="flex flex-col">
                <span>Discount Amount (in %):</span>
                <input
                  type="number"
                  className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                  placeholder="Enter Value"
                  name="discountAmt"
                  required
                  onChange={(e) => handleDiscountInfoInput(e)}
                />
              </div>
            )}
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="flex space-x-2 items-center font-medium ml-[3px] bg-yellow-light text-white py-2 px-10 rounded-xl uppercase hover:shadow-md"
            >
              Create
            </button>
          </div>
        </form>
      )}

      {list === 2 && (
        <form onSubmit={handleSubmitGiftCard}>
          <h1 className="text-xl">Create Gift Card</h1>
          <div className="flex flex- col justify- center items-center gap-10 flex-wrap pt-5">
            <div className="flex flex-col">
              <span>Minimum Order Value (Mini. Price):</span>
              <input
                type="number"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="minOrderValue"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Gift Card Amount (in $):</span>
              <input
                type="number"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="discountAmount"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Assigned To (Enter Ph. Number):</span>
              <input
                type="number"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="assignedTo"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
            <div className="flex flex-col">
              <span>Expiry Date of Gift Card:</span>
              <input
                type="date"
                className="bg-secondary-dark-bg h-11 rounded-2xl px-2 w-[20rem] text-white"
                placeholder="Enter Value"
                name="expiryDate"
                required
                onChange={(e) => handleDiscountInfoInput(e)}
              />
            </div>
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="flex space-x-2 items-center font-medium ml-[3px] bg-yellow-light text-white py-2 px-10 rounded-xl uppercase hover:shadow-md"
            >
              Create
            </button>
          </div>
        </form>
      )}

      {/* view disount */}
      {isViewDiscount ? (
        <>
          <div className="fixed inset-0 z-20 bg-black bg-opacity-45 backdrop-blur-sm">
            <div className="flex h-full justify-center items-center">
              <div className="bg-white lg:h-auto h-[80%] lg:w-[60%] w-[90%] rounded flex flex-col">
                <div
                  className="flex justify-end -mt-2 -mr-2 cursor-pointer"
                  onClick={() => {
                    setIsViewDiscount()
                  }}
                >
                  <svg
                    className="size-6 bg-white rounded border border-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="w-full flex flex- col justify-center items-center h- [25rem] lg:space-x-10">
                  {/* <h1>Discount Information</h1> */}
                  {/* <div className="w-full h-full flex flex-col items-center">
                    <span>{capitalize(isViewDiscount.type)} Information</span>
                    <div className="pt-6 flex justify-center gap-4 flex-wrap">
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Discount:
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Discount Amount"
                          value={isViewDiscount.discountAmount}
                          name="discountAmount"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          {capitalize(isViewDiscount.type)} Name:
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Coupon Name"
                          value={isViewDiscount.name}
                          name="name"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Assigned To:
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Coupon Name"
                          value={isViewDiscount.assignedTo}
                          name="name"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Available For (Location):
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Coupon Name"
                          value={capitalize(isViewDiscount.locationType)}
                          name="name"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm">
                          Expiry Date:
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="date"
                          value={moment(isViewDiscount.expiryDate).format(
                            "DD-MM-YYYY"
                          )}
                          name="name"
                        />
                      </div>
                    </div>
                  </div> */}

                  {isViewDiscount.type === "coupon" && (
                    <>
                      <div className="scale-75">
                        <div
                          id="coupon"
                          ref={couponRef}
                          className="border-2 border-yellow-light w-[720px] bg-black text-white rounded-2xl p-5 shadow-lg text-center"
                        >
                          <img
                            src={logo}
                            alt="Coupon Logo"
                            className="max-w-52 mx-auto mt-4"
                            onLoad={() => setIsImageLoaded(true)}
                            onError={(e) => {
                              console.error("Image failed to load", e)
                              setIsImageLoaded(false)
                            }}
                          />
                          <div className="text-4xl font-bold text-green-600">
                            {isViewDiscount.desc}{" "}
                            {isViewDiscount.discountAmount ===
                            isViewDiscount.maxDiscount
                              ? `$${isViewDiscount.discountAmount}`
                              : `${isViewDiscount.discountAmount}%`}{" "}
                            OFF
                          </div>
                          <div className="">
                            <input
                              type="text"
                              defaultValue="On your next purchase"
                              className="text-center w-full text-sm font-medium text-black mt-2 px-4 py-2 rounded-md"
                            />
                          </div>
                          <div className="font-medium mt-2">
                            Valid Till:{" "}
                            {moment(isViewDiscount.expiryDate).format(
                              "DD MM YYYY"
                            )}
                          </div>
                          <div className="bg-gray-200 text-black text-lg font-medium rounded-md px-2 py-1 mt-4 mx-auto max-w-xs">
                            {isViewDiscount.name}
                          </div>
                          <div className="font-medium mt-2">
                            Issue Date:{" "}
                            {moment(isViewDiscount.createdAt).format(
                              "DD MM YYYY"
                            )}
                          </div>
                        </div>
                        <button
                          className={`mt-4 px-4 py-2 rounded-md ${
                            isImageLoaded
                              ? "bg-yellow-medium_dark text-white"
                              : "bg-gray-500 text-gray-300 cursor-not-allowed"
                          }`}
                          onClick={download}
                          disabled={!isImageLoaded}
                        >
                          Download {capitalize(isViewDiscount.type)}
                        </button>
                      </div>
                    </>
                  )}
                  {isViewDiscount.type === "giftcard" && (
                    <>
                      <div className="scale-75">
                        <div
                          className="gift-card border-2 border-yellow-light w-[720px] rounded-2xl bg-black text-white p-5 shadow-lg text-center"
                          id="giftcard"
                          ref={couponRef}
                        >
                          <img
                            src={logo}
                            alt="Coupon Logo"
                            className="max-w-52 mx-auto mt-4"
                            onLoad={() => setIsImageLoaded(true)}
                            onError={(e) => {
                              console.error("Image failed to load", e)
                              setIsImageLoaded(false)
                            }}
                          />
                          <div className="amount text-3xl text-green-600 font-bold mb-3">
                            ${isViewDiscount.discountAmount} Gift Card
                          </div>
                          <div className="recipient text-lg mb-2">
                            To: {isViewDiscount.assignedTo}
                          </div>
                          <input
                            type="text"
                            defaultValue="Wishing you a wonderful day and lots of happiness. Enjoy your gift!"
                            className="message text-sm text-black mb-4 px-4 py-2 w-full text-center rounded-md"
                          />
                          <div className="sender text-lg mb-2">
                            From: Boost Mobile Detailing
                          </div>
                          <div className="flex justify-center gap-2">
                            <div className="font-medium">
                              Issue Date:{" "}
                              {moment(isViewDiscount.createdAt).format(
                                "DD MM YYYY"
                              )}
                            </div>
                            <span>-</span>
                            <div className="font-medium">
                              Valid until:{" "}
                              {moment(isViewDiscount.expiryDate).format(
                                "DD-MM-YYYY"
                              )}
                            </div>
                          </div>
                          <div className="code text-lg mt-1 font-bold uppercase">
                            Code: {isViewDiscount.name}
                          </div>
                        </div>
                        <button
                          className={`mt-4 px-4 py-2 rounded-md ${
                            isImageLoaded
                              ? "bg-yellow-medium_dark text-white"
                              : "bg-gray-500 text-gray-300 cursor-not-allowed"
                          }`}
                          onClick={download}
                          disabled={!isImageLoaded}
                        >
                          Download Coupon
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Discount
